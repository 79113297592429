import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const Post = ({ title, authorImage, slug, date, body, fluid, readingTime }) => {
  return (
    <Link className="card" to={slug}>
      <div className="card-image-container">
        <Img fluid={fluid}></Img>
      </div>
      <div className="card--title">
        <h2>{title}</h2>
        <p className="card--body">{body}</p>
        <div className="card--footer">
          <div className="avatar-wrapper">
            <Img fluid={authorImage}></Img>
          </div>
          <ul className="card--details">
            <li>{date}</li>
            <li>{readingTime}</li>
          </ul>
        </div>
      </div>
    </Link>
  )
}

export default Post
