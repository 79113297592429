import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, StaticQuery } from "gatsby"
import Post from "../components/Post"

const Blog = () => (
  <Layout>
    <Seo
      title="News &amp; Updates"
      description="Get the latest announcements and learn more about how we build Ready Five."
    />
    <section className="blog-container">
      <div className="container">
        <div
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="1000"
          className="section--header"
        >
          <h1 className="main-title" style={{ marginTop: "0" }}>
            News &amp; Updates
          </h1>
          <h4 className="sub-title">
            Get the latest announcements and learn more about how we build Ready
            Five.
          </h4>
        </div>
        <StaticQuery
          query={blogQuery}
          render={(data) => {
            return (
              <div
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
                className="grid grid-auto"
              >
                {data.allMarkdownRemark.edges.map(({ node }) => (
                  <Post
                    key={node.id}
                    title={node.frontmatter.title}
                    author={node.frontmatter.author}
                    authorImage={
                      node.frontmatter.authorImage.childImageSharp.fluid
                    }
                    date={node.frontmatter.date}
                    slug={node.fields.slug}
                    body={node.excerpt}
                    fluid={node.frontmatter.image.childImageSharp.fluid}
                    readingTime={node.fields.readingTime.text}
                  />
                ))}
              </div>
            )
          }}
        />
      </div>
    </section>
  </Layout>
)

const blogQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMM Do YYYY")
            author
            authorImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
            readingTime {
              text
            }
          }
          excerpt(pruneLength: 90)
        }
      }
    }
  }
`

export default Blog
